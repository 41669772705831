import CvsLogo from '@/components/SVG/Logo/CvsLogo';
import WalgreensLogo from '@/components/SVG/Logo/WalgreensLogo';
import WalmartLogo from '@/components/SVG/Logo/WalmartLogo';
import { Partners } from '@/types/partner';

function PartnerLogo() {
  if (process.env.NEXT_PUBLIC_PARTNER === Partners.WALMART) {
    return <WalmartLogo />;
  }

  if (process.env.NEXT_PUBLIC_PARTNER === Partners.CVS) {
    return <CvsLogo />;
  }

  return <WalgreensLogo />;
}

export default PartnerLogo;
