import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';
import Link from 'next/link';

import styles from './styles.module.scss';

export type ButtonType = 'button' | 'submit' | 'link' | 'reset';
export type ButtonWidth = 'auto' | 'full' | 'middle' | 'big';
export type ButtonSize = 'auto' | 'middle' | 'small' | 'xsmall' | 'big';
export type ButtonTheme =
  | 'primary'
  | 'white'
  | 'secondary'
  | 'primary3'
  | 'transparent'
  | 'noStyle'
  | 'underline'
  | 'success';

interface Props {
  type?: ButtonType;
  theme?: ButtonTheme;
  size?: ButtonSize;
  width?: ButtonWidth;
  form?: string;
  href?: string;
  dataTestid?: string;
  disabled?: boolean;
  callback?: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
  scroll?: boolean;
  replace?: boolean;
}

function Button({
  type = 'button',
  children,
  disabled = false,
  href,
  size = 'auto',
  width = 'auto',
  theme = 'primary',
  form,
  dataTestid,
  scroll = true,
  replace = false,
  callback = () => undefined,
}: PropsWithChildren<Props>) {
  return type !== 'link' ? (
    <button
      className={clsx(
        styles.button,
        styles[theme],
        styles[size],
        styles[`width-${width}`],
        disabled && styles.disabled,
      )}
      disabled={disabled}
      form={form}
      type={type} // eslint-disable-line react/button-has-type
      onClick={callback}
      data-testid={dataTestid}
    >
      {children}
    </button>
  ) : (
    <Link
      href={href || ''}
      scroll={scroll}
      replace={replace}
      className={clsx(
        styles.link,
        styles[theme],
        styles[size],
        styles[`width-${width}`],
        disabled && styles.disabled,
      )}
      data-testid={dataTestid}
      onClick={callback}
    >
      {children}
    </Link>
  );
}

export default Button;
