'use client';

import { PropsWithChildren } from 'react';
import clsx from 'clsx';

import { sendLog } from '@/services/logs';

import styles from './styles.module.scss';

interface Props {
  event: string;
  value?: Record<string, unknown>;
  now?: boolean;
  full?: boolean;
}

export default function SendLogOnClickWrapper({
  children,
  event,
  value,
  now,
  full,
}: PropsWithChildren<Props>) {
  return (
    <div
      className={clsx(styles.container, full && styles.full)}
      onClick={() =>
        sendLog(
          event,
          {
            pathname: window?.location.pathname,
            ...value,
          },
          { now },
        )
      }
    >
      {children}
    </div>
  );
}
