import React from 'react';

import styles from './styles.module.scss';

function CvsLogo() {
  return (
    <svg viewBox="0 0 52.088 21">
      <g transform="translate(-304.156 -450.281)">
        <path
          className={styles.a}
          d="M615.621,459.3c.2.942.739,1.275,1.848,1.275.979,0,1.515-.351,1.515-.905,0-.794-.721-.85-2.217-1.238a12.394,12.394,0,0,1-3.4-1.219,3.323,3.323,0,0,1-1.441-2.9c0-2.439,1.922-4.028,5.266-4.028,3.252,0,5.155,1.423,5.376,3.972h-3.824c-.129-.757-.647-1.109-1.626-1.109-.85,0-1.275.277-1.275.85,0,.665.647.794,1.866,1.108a12.313,12.313,0,0,1,3.6,1.2,3.25,3.25,0,0,1,1.607,2.938c0,2.734-1.958,4.342-5.617,4.342-3.381,0-5.358-1.589-5.617-4.286Z"
          transform="translate(-266.676 0)"
        />
        <path
          className={styles.a}
          d="M447.967,458.336c-.222,3.307-2.4,5.247-6.023,5.247-4.028,0-6.466-2.55-6.466-6.651,0-4.12,2.476-6.651,6.393-6.651,3.658,0,5.8,1.848,6.041,5.118h-3.843a1.933,1.933,0,0,0-2.143-1.958c-1.589,0-2.365,1.164-2.365,3.492,0,2.291.85,3.473,2.494,3.473a2.035,2.035,0,0,0,2.125-2.088h3.787Z"
          transform="translate(-113.876 0)"
        />
        <path
          className={styles.a}
          d="M522.956,453.062h4.12l2.125,8.129,2.272-8.129h3.972l-4.231,12.6h-4.028Z"
          transform="translate(-189.733 -2.412)"
        />
        <path
          className={styles.a}
          d="M308.65,450.42a1.8,1.8,0,0,0-1.312.554l-2.642,2.642a1.885,1.885,0,0,0,0,2.642l7.464,7.446,7.446-7.464a1.885,1.885,0,0,0,0-2.642l-2.642-2.624a1.851,1.851,0,0,0-2.642,0l-2.162,2.18-2.18-2.18A1.875,1.875,0,0,0,308.65,450.42Z"
          transform="translate(0 -0.121)"
        />
        <g transform="translate(304.156 465.922)">
          <path
            className={styles.a}
            d="M307.47,570.487a1.71,1.71,0,0,1,.751.629,1.887,1.887,0,0,1,0,1.923,1.677,1.677,0,0,1-.751.629,2.828,2.828,0,0,1-1.154.21h-1v1.378h-1.156v-4.989h2.155a2.8,2.8,0,0,1,1.154.218Zm-.426,2.227a.9.9,0,0,0,.016-1.266l-.016-.017a1.2,1.2,0,0,0-.79-.226h-.942v1.735h.941a1.216,1.216,0,0,0,.79-.224Z"
            transform="translate(-304.156 -569.968)"
          />
          <path
            className={styles.a}
            d="M346.3,569.845a1.675,1.675,0,0,1,.435,1.269v2.2h-1.1v-2.025a1.009,1.009,0,0,0-.2-.681.734.734,0,0,0-.583-.224.892.892,0,0,0-.671.26,1.081,1.081,0,0,0-.249.774V573.3h-1.116v-5.28h1.111v1.85a1.5,1.5,0,0,1,.534-.339,1.949,1.949,0,0,1,.684-.118,1.575,1.575,0,0,1,1.158.428Z"
            transform="translate(-337.677 -568.021)"
          />
          <path
            className={styles.a}
            d="M379.172,582.22a1.865,1.865,0,0,1-.744-.7,2.04,2.04,0,0,1,0-2.038,1.865,1.865,0,0,1,.751-.7,2.452,2.452,0,0,1,2.176,0,1.9,1.9,0,0,1,.749.7,2.054,2.054,0,0,1,0,2.038,1.9,1.9,0,0,1-.749.7,2.452,2.452,0,0,1-2.176,0Zm1.791-.948a1.226,1.226,0,0,0,0-1.547.93.93,0,0,0-.7-.289.941.941,0,0,0-.706.289,1.22,1.22,0,0,0,0,1.547.941.941,0,0,0,.706.289A.929.929,0,0,0,380.963,581.272Z"
            transform="translate(-368.324 -577.126)"
          />
          <path
            className={styles.a}
            d="M414.913,577.081a1.114,1.114,0,0,1-.4.183,2.074,2.074,0,0,1-.5.061,1.5,1.5,0,0,1-1.048-.35,1.335,1.335,0,0,1-.373-1.025v-1.577h-.6v-.855h.6v-.934h1.107v.933h.957v.855h-.966v1.563a.518.518,0,0,0,.126.373.459.459,0,0,0,.353.132.705.705,0,0,0,.448-.143Z"
            transform="translate(-397.659 -571.977)"
          />
          <path
            className={styles.a}
            d="M436.673,582.212a1.866,1.866,0,0,1-.751-.7,2.054,2.054,0,0,1,0-2.038,1.865,1.865,0,0,1,.751-.7,2.451,2.451,0,0,1,2.176,0,1.875,1.875,0,0,1,.762.7,2.055,2.055,0,0,1,0,2.038,1.876,1.876,0,0,1-.749.7,2.452,2.452,0,0,1-2.177,0Zm1.791-.948a1.227,1.227,0,0,0,0-1.548.925.925,0,0,0-.7-.289.941.941,0,0,0-.706.289,1.22,1.22,0,0,0,0,1.548.941.941,0,0,0,.706.289.925.925,0,0,0,.708-.289Z"
            transform="translate(-418.183 -577.119)"
          />
          <path
            className={styles.a}
            d="M492.084,570.488a1.7,1.7,0,0,1,.753.629,1.887,1.887,0,0,1,0,1.923,1.677,1.677,0,0,1-.753.629,2.812,2.812,0,0,1-1.151.21h-1v1.378h-1.156v-4.989h2.16A2.779,2.779,0,0,1,492.084,570.488Zm-.419,2.229a.9.9,0,0,0,.016-1.267l-.016-.017a1.2,1.2,0,0,0-.79-.226h-.941v1.735h.941a1.216,1.216,0,0,0,.787-.226Z"
            transform="translate(-464.246 -569.969)"
          />
          <path
            className={styles.a}
            d="M527.9,578.924a1.619,1.619,0,0,1,.478,1.279v2.189h-1.048v-.478a1.236,1.236,0,0,1-1.168.534,1.811,1.811,0,0,1-.767-.151,1.14,1.14,0,0,1-.5-.419,1.082,1.082,0,0,1-.172-.6,1,1,0,0,1,.4-.839,2.012,2.012,0,0,1,1.244-.306h.883a.715.715,0,0,0-.21-.559.98.98,0,0,0-.663-.195,1.9,1.9,0,0,0-.6.1,1.585,1.585,0,0,0-.5.26l-.4-.778a2.461,2.461,0,0,1,.753-.342,3.42,3.42,0,0,1,.9-.122A2,2,0,0,1,527.9,578.924Zm-.948,2.644a.721.721,0,0,0,.312-.387v-.392H526.5q-.686,0-.686.448a.4.4,0,0,0,.168.337.751.751,0,0,0,.459.126.967.967,0,0,0,.511-.133Z"
            transform="translate(-495.427 -577.102)"
          />
          <path
            className={styles.a}
            d="M561.372,578.679a2,2,0,0,1,.78-.143v1.024c-.124,0-.21-.014-.249-.014a1.018,1.018,0,0,0-.749.269,1.079,1.079,0,0,0-.271.8v1.812H559.77v-3.835h1.063v.507a1.23,1.23,0,0,1,.544-.421Z"
            transform="translate(-525.813 -577.139)"
          />
          <path
            className={styles.a}
            d="M583.275,577.081a1.116,1.116,0,0,1-.4.183,2.048,2.048,0,0,1-.5.061,1.494,1.494,0,0,1-1.048-.35,1.335,1.335,0,0,1-.375-1.025v-1.577h-.591v-.855h.591v-.934h1.113v.933h.954v.855h-.954v1.563a.518.518,0,0,0,.124.373.464.464,0,0,0,.353.132.71.71,0,0,0,.45-.143Z"
            transform="translate(-543.664 -571.977)"
          />
          <path
            className={styles.a}
            d="M610.51,578.981a1.675,1.675,0,0,1,.435,1.269v2.2h-1.113V580.42a1.009,1.009,0,0,0-.21-.681.733.733,0,0,0-.577-.224.891.891,0,0,0-.671.26,1.082,1.082,0,0,0-.244.774v1.887h-1.1V578.61h1.061v.455a1.482,1.482,0,0,1,.544-.375,1.907,1.907,0,0,1,.719-.132A1.575,1.575,0,0,1,610.51,578.981Z"
            transform="translate(-566.792 -577.156)"
          />
          <path
            className={styles.a}
            d="M646.351,580.865h-2.9a.9.9,0,0,0,.371.562,1.226,1.226,0,0,0,.728.21,1.444,1.444,0,0,0,.53-.09,1.286,1.286,0,0,0,.435-.281l.591.642a1.974,1.974,0,0,1-1.583.629,2.484,2.484,0,0,1-1.147-.254,1.84,1.84,0,0,1-.769-.7,1.919,1.919,0,0,1-.272-1.019,1.959,1.959,0,0,1,.269-1.015,1.874,1.874,0,0,1,.733-.706,2.177,2.177,0,0,1,1.048-.254,2.139,2.139,0,0,1,1.019.244,1.769,1.769,0,0,1,.713.677,2.068,2.068,0,0,1,.26,1.048C646.372,580.585,646.365,580.683,646.351,580.865Zm-2.6-1.235a.9.9,0,0,0-.315.585h1.888a.925.925,0,0,0-.312-.581.944.944,0,0,0-.629-.21.958.958,0,0,0-.631.206Z"
            transform="translate(-597.406 -577.183)"
          />
          <path
            className={styles.a}
            d="M679.928,578.679a2.011,2.011,0,0,1,.78-.143v1.024c-.124,0-.21-.014-.249-.014a1.022,1.022,0,0,0-.749.269,1.079,1.079,0,0,0-.271.8v1.812h-1.111v-3.835h1.061v.507A1.243,1.243,0,0,1,679.928,578.679Z"
            transform="translate(-628.621 -577.14)"
          />
        </g>
      </g>
    </svg>
  );
}

export default CvsLogo;
