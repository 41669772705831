enum Brand {
  // Picta | Walgreens
  //       | photo partner
  DEFAULT = 'DEFAULT',

  // Picta x Walgreens
  LARGE = 'LARGE',

  // Picta x W Partner (used for Walgreens only)
  PARTNER_LOGO = 'PARTNER_LOGO',
}

// eslint-disable-next-line import/prefer-default-export
export { Brand };
