import { Partners } from '@/types/partner';

import CvsOnlyLogo from './CVSOnlyLogo';
import WalgreensOnlyLogo from './WalgreensOnlyLogo';
import WalmartOnlyLogo from './WalmartOnlyLogo';

export default function PartnerOnlyLogo() {
  if (process.env.NEXT_PUBLIC_PARTNER === Partners.WALMART) {
    return <WalmartOnlyLogo />;
  }

  if (process.env.NEXT_PUBLIC_PARTNER === Partners.CVS) {
    return <CvsOnlyLogo />;
  }

  return <WalgreensOnlyLogo />;
}
